import React from 'react';

export default function Sharer (props) {

    return (
        <div className='flex text-center mx-auto w-28 mt-6'> 

            <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${props.url}&title=${props.title}`} className=''>
                <span className='text-smcolor hover:text-smcolor-dark'>
                <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
            <rect x="2" y="9" width="4" height="12" />
            <circle cx="4" cy="4" r="2" />
          </svg>                    
                </span>                
            </a>

            <a href={`https://twitter.com/intent/tweet/?text=${props.title}&url=${props.url}%2F&via=skillmeter`} className='ml-4'>
            <span className='text-smcolor hover:text-smcolor-dark'>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            >
                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
            </svg>  
            </span>          
            </a>

            <a href={`https://www.facebook.com/sharer/sharer.php?u=${props.url}`} className='ml-4'>
                <span className='text-smcolor hover:text-smcolor-dark'>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                </svg>
                </span>                
            </a>


        </div>     
    );
}